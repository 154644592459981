function act_page_tree_menu_init() {

    const $closes = document.querySelectorAll('.act-page-tree-menu-children__button');
    const $menus = document.querySelectorAll('.act-page-tree-menu');
    const $main = document.getElementsByTagName('main');
    const $overlay_menu = document.querySelectorAll('.act-page-tree-menu__overlay');

    let $is_desktop = true;
    if (window.innerWidth < 1024) {
        $is_desktop = false;
    }
    var mql_test = window.matchMedia('(min-width: 1024px)');

    mql_test.addEventListener('change', (e) => {
        if (e.matches) {
            $is_desktop = true;
        }
        else {
            $is_desktop = false;
        }
    });

    if ($closes) {
        $closes.forEach(function ($close) {
            $close.addEventListener('click', (e) => {
                if ($is_desktop) {
                    e.target.closest('li').firstChild.click();
                    e.target.closest('li').firstChild.focus();
                }
            });
        });
    }
    if ($menus) {


        $menus.forEach(function ($menu) {

            $menu.classList.add('act-page-tree-menu--closed');

            const $panels = $menu.querySelectorAll('.act-page-tree-menu__panel');
            if ($panels) {
                $panels.forEach($el => {
                    $el.parentNode.classList.add('act-page-tree-menu__item--panel-closed');
                })
            }

            $main[0].addEventListener('focus', (e) => {

                if ($is_desktop) {
                    close_all_panels();
                    $menu.classList.remove('act-page-tree-menu--opened');
                    $menu.classList.add('act-page-tree-menu--closed');
                }
            }, true);
            $menu.addEventListener('focus', (e) => {

                if ($is_desktop) {
                    if (e.target.parentNode.classList.contains('act-page-tree-menu__item--depth-0')) {
                        if (e.target.parentNode.classList.contains('act-page-tree-menu__item--panel-closed')) {
                            close_all_panels();
                            $menu.classList.remove('act-page-tree-menu--opened');
                            $menu.classList.add('act-page-tree-menu--closed');
                        }
                    }
                }
            }, true);



            $menu.addEventListener('click', (e) => {
                if (e.target.nodeName == 'use' || e.target.nodeName == 'svg') {
                    e.preventDefault();
                    e.target.closest('button').click();
                }

                if (e.target.parentNode.classList.contains('act-page-tree-menu__item--depth-0') && e.target.parentNode.classList.contains('act-page-tree-menu__item--has-children')) {
                    e.preventDefault();
                    if ($is_desktop) {
                        if (e.target.parentNode.classList.contains('act-page-tree-menu__item--panel-closed')) {
                            close_all_panels();
                            $menu.classList.remove('act-page-tree-menu--opened');
                            $menu.classList.add('act-page-tree-menu--closed');
                        }
                    }
                    toggle_panel.call(e.target);
                    if ($is_desktop) {

                        $menu.classList.toggle('act-page-tree-menu--opened');
                        $menu.classList.toggle('act-page-tree-menu--closed');
                    }
                }

                if (e.target.classList.contains('act-page-tree-menu__button')) {
                    toggle_menu.call(e.target);
                }


            });

            document.onkeydown = function (evt) {
                evt = evt || window.event;
                if (evt.keyCode == 27) {
                    if ($menu.classList.contains('act-page-tree-menu--opened')) {
                        toggle_menu();
                    }
                }
            };
            $overlay_menu[0].addEventListener('click', (e) => {
                if ($menu.classList.contains('act-page-tree-menu--opened')) {
                    toggle_menu();
                }

            });



            function close_all_panels() {
                $menu.querySelectorAll('.act-page-tree-menu__item--panel-opened').forEach($el => {
                    $el.classList.remove('act-page-tree-menu__item--panel-opened');
                    $el.classList.add('act-page-tree-menu__item--panel-closed');
                });

            }

            function toggle_menu() {
                if ($menu.classList.contains('act-page-tree-menu--dropdown-opened')) {
                    $menu.classList.toggle('act-page-tree-menu--dropdown-opened');
                }
                else {
                    $menu.classList.toggle('act-page-tree-menu--opened');
                }


                $menu.classList.toggle('act-page-tree-menu--closed');
                close_all_panels();

            }

            function toggle_panel() {
                const $parent = this.parentNode;
                $parent.classList.toggle('act-page-tree-menu__item--panel-opened');
                $parent.classList.toggle('act-page-tree-menu__item--panel-closed');

            }
        }
        );

    }

}

act_page_tree_menu_init();
